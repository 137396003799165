exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-halal-meaning-js": () => import("./../../../src/pages/about/halal-meaning.js" /* webpackChunkName: "component---src-pages-about-halal-meaning-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-international-approval-js": () => import("./../../../src/pages/about/international-approval.js" /* webpackChunkName: "component---src-pages-about-international-approval-js" */),
  "component---src-pages-about-locations-js": () => import("./../../../src/pages/about/locations.js" /* webpackChunkName: "component---src-pages-about-locations-js" */),
  "component---src-pages-about-structure-js": () => import("./../../../src/pages/about/structure.js" /* webpackChunkName: "component---src-pages-about-structure-js" */),
  "component---src-pages-about-vision-js": () => import("./../../../src/pages/about/vision.js" /* webpackChunkName: "component---src-pages-about-vision-js" */),
  "component---src-pages-admin-applies-js": () => import("./../../../src/pages/admin/applies.js" /* webpackChunkName: "component---src-pages-admin-applies-js" */),
  "component---src-pages-admin-certificates-js": () => import("./../../../src/pages/admin/certificates.js" /* webpackChunkName: "component---src-pages-admin-certificates-js" */),
  "component---src-pages-admin-checks-js": () => import("./../../../src/pages/admin/checks.js" /* webpackChunkName: "component---src-pages-admin-checks-js" */),
  "component---src-pages-admin-complaints-js": () => import("./../../../src/pages/admin/complaints.js" /* webpackChunkName: "component---src-pages-admin-complaints-js" */),
  "component---src-pages-admin-feedbacks-js": () => import("./../../../src/pages/admin/feedbacks.js" /* webpackChunkName: "component---src-pages-admin-feedbacks-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-questions-js": () => import("./../../../src/pages/admin/questions.js" /* webpackChunkName: "component---src-pages-admin-questions-js" */),
  "component---src-pages-admin-users-js": () => import("./../../../src/pages/admin/users.js" /* webpackChunkName: "component---src-pages-admin-users-js" */),
  "component---src-pages-admin-users-requests-js": () => import("./../../../src/pages/admin/users-requests.js" /* webpackChunkName: "component---src-pages-admin-users-requests-js" */),
  "component---src-pages-certification-auditing-js": () => import("./../../../src/pages/certification/auditing.js" /* webpackChunkName: "component---src-pages-certification-auditing-js" */),
  "component---src-pages-certification-benefits-js": () => import("./../../../src/pages/certification/benefits.js" /* webpackChunkName: "component---src-pages-certification-benefits-js" */),
  "component---src-pages-certification-categories-js": () => import("./../../../src/pages/certification/categories.js" /* webpackChunkName: "component---src-pages-certification-categories-js" */),
  "component---src-pages-certification-procedure-js": () => import("./../../../src/pages/certification/procedure.js" /* webpackChunkName: "component---src-pages-certification-procedure-js" */),
  "component---src-pages-certification-services-js": () => import("./../../../src/pages/certification/services.js" /* webpackChunkName: "component---src-pages-certification-services-js" */),
  "component---src-pages-check-certificate-[key]-jsx": () => import("./../../../src/pages/check-certificate/[key].jsx" /* webpackChunkName: "component---src-pages-check-certificate-[key]-jsx" */),
  "component---src-pages-contact-apply-js": () => import("./../../../src/pages/contact/apply.js" /* webpackChunkName: "component---src-pages-contact-apply-js" */),
  "component---src-pages-contact-check-js": () => import("./../../../src/pages/contact/check.js" /* webpackChunkName: "component---src-pages-contact-check-js" */),
  "component---src-pages-contact-complaint-js": () => import("./../../../src/pages/contact/complaint.js" /* webpackChunkName: "component---src-pages-contact-complaint-js" */),
  "component---src-pages-contact-feedback-js": () => import("./../../../src/pages/contact/feedback.js" /* webpackChunkName: "component---src-pages-contact-feedback-js" */),
  "component---src-pages-contact-information-js": () => import("./../../../src/pages/contact/information.js" /* webpackChunkName: "component---src-pages-contact-information-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-policies-confidentiality-and-impartiality-js": () => import("./../../../src/pages/policies/confidentiality-and-impartiality.js" /* webpackChunkName: "component---src-pages-policies-confidentiality-and-impartiality-js" */),
  "component---src-pages-policies-fee-regulation-js": () => import("./../../../src/pages/policies/fee-regulation.js" /* webpackChunkName: "component---src-pages-policies-fee-regulation-js" */),
  "component---src-pages-policies-guarantee-js": () => import("./../../../src/pages/policies/guarantee.js" /* webpackChunkName: "component---src-pages-policies-guarantee-js" */),
  "component---src-pages-policies-integrity-js": () => import("./../../../src/pages/policies/integrity.js" /* webpackChunkName: "component---src-pages-policies-integrity-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

